// extracted by mini-css-extract-plugin
export var footer = "qd";
export var footerBottom = "xd";
export var footerBottom__copyWrapper = "Bd";
export var footerBottom__footerLink = "Ad";
export var footerBottom__footerSocial = "Dd";
export var footerBottom__left = "yd";
export var footerBottom__links = "zd";
export var footerBottom__textDeveloper = "Cd";
export var footerTop = "sd";
export var footerTop__appButton = "vd";
export var footerTop__logo = "wd";
export var footerTop__menu = "ud";
export var footerWrapper = "rd";
export var withButton = "td";